<template>
  <div class="container">
    <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/microdrama25/microdrama_top.png"
      
    />
    <div class="content">
      <van-form>
      <img
      class="img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/microdrama25/hearder.png"
      
    />
        <van-field
          v-model="form.phone"
          type="tel"
          maxlength="11"
          placeholder="请输入您的手机号码"
          class="form-phone"
        />
        <van-field
          v-model="form.code"
          type="number"
          center
          maxlength="6"
          autocomplete="one-time-code"
          placeholder="请输入短信验证码"
          class="input-code"
        >
          <template slot="right-icon">
            <div class="code" v-show="sendAuthCode" @click="getAuthCode">
              获取验证码
            </div>
            <div class="code" v-show="!sendAuthCode">
              <span>{{ auth_time }}秒</span>重新获取
            </div>
          </template>
        </van-field>

        <img
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/microdrama25/btn-pic.png"
          class="submit"
          @click="onSubmit"
        />

        <div class="checked">
          <van-checkbox
            checked-color="#ffffff"
            v-model="checked"
            icon-size="22px"
          >
          <p> 
              我已详细阅读并同意<span class="book" @click.top="onScroll"
              >《活动规则》</span
            >及<span class="book" @click="dialogInfo('《个人信息保护授权协议》')">《隐私条款》</span>
          </p>
            
          </van-checkbox>
        <div class="tip_div">
            <p> 温馨提示：</p>
            <p> 当月不退订次月自动续订，产品资费25元/月</p>
            <p> 产品提供方:中文在线集团股份有限公司</p>
            <p> 服务提供方:联通沃音乐文化有限公司</p>
            <p> 客服热线:4001189599</p>
        </div>
        </div>
      </van-form>
      <img
        class="img"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/microdrama25/pic1.png"
      />
      <img
        class="img"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/microdrama25/pic2.png"
      />
      <img
        class="img"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/LTLiaoNing/microdrama25/pic3.png"
      />

      <div class="info">
        <div class="title">· 产品说明 ·</div>
        <div class="text">1、产品名称：视频彩铃应用型产品尊享版25元包月（微剧版）</div>
        <div class="text">2、产品资费：25元/月</div>
        <div class="text">
          3、活动时间：2024年05月30日-2024年12月31日
        </div>
        <div class="text">
         4、活动对象：中国联通4G/5G手机用户
        </div>
        <div class="text">5、计费规则：</div>
        <div class="text">
          （1）订购视频彩铃应用型产品尊享版25元包月（微剧版）-当月生效业务，扣费后立即生效。当月如不退订，次月1日系统自动扣除包月费用,每月自动续订,从话费中扣取费用。
        </div>
        <div class="text">6、活动内容：成为“视频彩铃应用型产品尊享版25元包月（微剧版）会员”可享有订购前12个月每月权益N选1、短剧不限次免流观看和短剧彩铃订阅设置。</div>
        <div class="text">
          【热门权益N选1】
        </div>
        <div class="text">
         （1）订购成功后，前12个月每月可获得热门互联网每月选1款的权益，逾期未领取视作自动放弃使用机会，不予补发。
        </div>
        <div class="text">（2）可通过页面点击跳转领取页面领取，领取后，VIP月卡将充值到您开通的手机号码中，使用该手机号到指定APP上登录即享VIP特权。如领取权益失败，请检查APP账号是否有注册和绑定相应的手机号码，请确认注册和绑定相应的手机号码后，再进行领取使用。</div>
        <div class="text">
        （3）领取规则：超值权益：当月领取权益，若当月未领取，逾期无效；限量权益：每天8点更新，当日领完即止。
        </div>
        <div class="text">【短剧免流观看&彩铃设置】</div>
        <div class="text">在产品订购有效期间，您可以搜索“联通视频彩铃”微信小程序，在发现一视彩号专区一爆款短剧，不限次观看频道内短剧内容，或订阅精选短剧彩铃为主叫彩铃或彩振一体。</div>
        <div class="text">
         7、权益内容及视频彩铃等会员服务将根据资源合作情况不定期调整，请以页面展示为准。
        </div>
        <div class="text">
         8、可在本页面设置短剧彩铃和免流观看短剧内容。
        </div>
        <div class="text">9、每个手机号仅限参与一次，用户退订后再订购，则无法继续享有前12个月领取权益资格。</div>
        <div class="text">10、退订说明:</div>
        <div class="text">
         (1)人工客服退订:用户可拨打联通当地客服热线4000410155或10010，咨询相关业务退订操作。
        </div>
        <div class="text">(2)线上自助退订:微信登录联通视频彩铃小程序，通过右下方“我的”>“我的彩铃会员”，找到对应已开通的会员产品，点击“退订”，即可实现包月产品退订。</div>
        <div class="text">11、其他说明:</div>
        <div class="text">(1)目前视频彩铃业务仅支持4G/5G用户使用。</div>
        <div class="text">
         (2)如您设置了主叫彩铃，当您拨打电话时，您的手机型号(需开通VOLTE服务)支持“视频彩铃”时，通话等待期间可以看到自己订阅的视频彩铃;如您设置了彩振一体，当您的手机型号(需开通VOLTE服务)支持“彩振一体”，Ta的手机型号(需开通VOLTE服务)支持“视频彩铃’时，通话等待期间您和Ta均可以看到您订阅的视频彩铃，否则将播放音频彩铃。点击检测手机型号是否支持。
        </div>
        <div class="text">(3)关于免流服务，是我们面向会员产品订购用户推出的福利政策在免流服务下，在活动营销页和“联通视频彩铃”微信小程序观看短剧内容时，免收取流量费用。免流服务仅供中国大陆地区使用，不含中国港澳台地区、国外地区。不支持结转、转赠、与他人共享等。</div>
        <div class="text">(4)如有疑问或咨询，请致电4001189599。</div>
        <div class="text">
          12、业务提供方：中文在线集团股份有限公司
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVerificationCode, tradeOrder } from "@/api/qiuMoFusion/index";
import tailJumpMixin from "@/utils/tailJump.js";

export default {
  mixins: [tailJumpMixin],
  data() {
    return {
      form: {
        productNo: "914208283552",
        phone: "",
        characteristic: "",
        marketUrl: "",
        sid: "",
        data: "",
        code: "",
        orderId: "",
      },
      num: 1,
      checked: false,
      yzm_loading: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
    };
  },
  mounted() {
    const that = this;
    window.onpopstate = function (event) {
      window.location.replace(
        that.tailJumpUrl(that.link, "QIUMORONGHE", that.form.phone, "退出")
      );
    }
  },
  computed: {
    link() {
      return `${location.origin}/pages/fusion/LTVideoRingtone/liaoning/likeListening`;
    },
  },
  watch: {
    form: {
      handler(value) {
        const { phone = "", code } = value;
        if (phone.length < 11) {
          this.num = 1;
        } else if (this.sendAuthCode && code.length == 0) {
          this.num = 2;
        } else if (code.length < 6) {
          this.num = 3;
        } else if (code.length == 6 && phone.length == 11) {
          this.num = 4;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const { from = "", phone = "", reasonCode = "" } = this.$route.query;
    const { href = "" } = window.location;
    this.form = {
      ...this.form,
      characteristic: from + reasonCode,
      marketUrl: href,
      phone,
    };
    let state = {
      url: document.URL,
    };
    history.pushState(state, "title", document.URL);
  },
  methods: {
    onScroll() {
      const ruleEl = document.querySelector(".info");
      ruleEl.scrollIntoView({ behavior: "smooth" });
    },
    getAuthCode() {
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else {
        this.yzm_loading = true;
        getVerificationCode(JSON.stringify({...this.form,appPackage: navigator.userAgent}))
          .then((res) => {
            this.yzm_loading = false;
            if (res.code != 0) {
              this.$dialog.alert({ message: res.message || "验证码获取失败" });
              setTimeout(() => {
                window.location.replace(
                  this.tailJumpUrl(
                    this.link,
                    "QIUMORONGHE",
                    this.form.phone,
                    "验证码下发失败"
                  )
                );
              }, 3000);
            } else {
              this.form.data = res.data.data.data;
              this.form.orderId = res.data.data.orderId;
              // 设置倒计时秒
              this.sendAuthCode = false;
              this.auth_time = 120;
              let auth_timetimer = setInterval(() => {
                this.auth_time--;
                if (this.auth_time <= 0) {
                  this.sendAuthCode = true;
                  clearInterval(auth_timetimer);
                }
              }, 1000);
            }
          })
          .catch((error) => {
            this.yzm_loading = false;
          });
      }
    },
    handleSubmit() {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      tradeOrder(JSON.stringify(this.form)).then((res) => {
        this.close();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message || "提交失败",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(this.link, "QIUMORONGHE", this.form.phone, "失败")
            );
          }, 3000);
        } else {
          this.$dialog.alert({
            title: "提交成功",
            message: "具体结果，请以短信提示为准",
            messageAlign: "left",
          });
          setTimeout(() => {
            window.location.replace(
              this.tailJumpUrl(this.link, "QIUMORONGHE", this.form.phone, "成功")
            );
          }, 3000);
        }
      });
    },
    onSubmit() {
      var filtercode = /^\d{6}$/;
      var filterPhone = /^1[3456789]\d{9}$/;
      if (!this.form.phone) {
        this.$dialog.alert({ message: "请输入手机号" });
      } else if (!filterPhone.test(this.form.phone)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!this.form.code) {
        this.$dialog.alert({ message: "请输入验证码" }).then(() => {
          if (this.sendAuthCode) {
            this.getHnPhoneCheck();
          }
        });
      } else if (!filtercode.test(this.form.code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$toast({
          duration: 8000,
          message: "请勾选协议",
        });
      } else {
        this.handleSubmit();
      }
    },
   dialogInfo(title) {
    const text1 = `尊敬的客户：
您好！
根据《中华人民共和国网络安全法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在我公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理店等）办理移动电话（含无线上网卡）、固定电话、家庭宽带入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效身份证件原件并进行查验、登记。登记信息包括姓名或名称、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效身份证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外提供客户个人信息：
1）事先获得客户的明确授权；
2）根据有关的法律法规要求；
3）按照相关司法机关和/或政府主管部门的要求；
4）为维护社会公众的利益所必需且适当；
5）为维护我公司或客户的合法权益所必需且适当。
`;

      this.$dialog.alert({
        messageAlign: "left",
        title: title,
        message: text1,
        className:'dialog-div'
        })
    },
     close() {
      this.visibility = false;
      this.$toast.clear();
    },

  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #e54853;
  padding-bottom: 160px;
  .img {
    width: 100%;
  }

  .content {
    margin-top:8px;
    ::v-deep .van-form {
      position: relative;
      background-color: rgb(255, 255, 255);
      width: 90%;
      margin: 0px auto;
      border-radius: 20px;
      padding-top: 40px;
      border: 6px solid rgb(255, 174, 38);

      .van-cell {
        width: 620px;
        height: 90px;
        background-color: #ffffff;
        border-radius: 80px;
        margin: 0 auto;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 0 0 40px;
        border-radius: 45px;
        border: 1px solid #000 !important;
        &.form-phone{
            margin-top:40px;
        }
        &.input-code {
          margin: 44px auto;

          .code {
            width: 186px;
            height: 36px;
            text-align: center;
            line-height: 36px;
            color: #ff7900;
            font-size: 28px;
            font-weight: normal;
            border-left: 2px solid #d1d1d1;
          }
        }

        .van-field__body {
          height: 100%;

          input {
            height: 100%;
          }
        }

        &::after {
          border: none;
        }

        .van-field__control {
          font-size: 28px;
          font-weight: 400;
          &::-webkit-input-placeholder {
            font-size: 28px;
            font-weight: 500;
            color: #999;
          }
        }
      }
      .submit {
        width: 630px;
        display: block;
        margin: 0px auto 60px;
        animation: identifier 1.5s infinite;

        @keyframes identifier {
          0% {
            transform: scale(1);
          }
          25% {
            transform: scale(0.9);
          }
          50% {
            transform: scale(1);
          }
          75% {
            transform: scale(0.9);
          }
        }
      }

      .checked {
        color: #323233;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        padding: 40px 40px 0;
        transform: translateY(-60px);

        .van-checkbox {
          width: auto;

          &__label {
            font-weight: 400;

            margin-left: 4px;
            color: #323233 !important;

            .book {
              color: red;
            }
          }
          &__icon--checked .van-icon {
            color: #e54853;
          }
        }
      }
      .tip_div{
        text-align:center;
        color:#323233;
        font-weight:400;
        font-size:24px;
      }
    }

    .img {
      box-sizing: border-box;
    }

    .info {
      text-align: justify;
      width: 90%;
      font-size: 32px;
      margin: 0 auto;
      margin-bottom: 50px;
      background-color: #fff;
      color: #000;
      border-radius: 15px;
      padding: 40px 30px;
      
      box-sizing: border-box;
      line-height: 50px;

      .title {
       font-size: 40px;
        text-align: center;
        padding-bottom: 20px;
        font-weight: 700;
      }
      .text{
        font-weight: 200 !important;
      }
    }
  }
}
.van-dialog{
    ::v-deep .van-dialog__message--has-title{
        color:rgba(0,0,0,0.9) !important;
    }
}
</style>
